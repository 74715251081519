//adapters contains the list of API helper functions
// import axios from "axios";
// import config from "../../config/config";
import { axiosInstance } from "../axiosInstance";
import momentTimeZone from "moment-timezone";
import { googleMapsPlacesAPI } from "../../components/common/GoogleAutoComplete";

// export const axiosInstance = axios.create({
//   baseURL: config.apiBaseUrl,
// });
export function activeCityListAPI() {
  return axiosInstance({
    method: "get",
    url: "state_city.php?active=1",
    data: {
      active: 1,
    },
  });
}

export function saveDataToSession(data) {
  const payload = JSON.stringify(data);
  return axiosInstance({
    method: "POST",
    headers: { "Content-Type": "application/json" },
    url: "estimate_session_service.php",
    data: payload,
  });
}

export function getInventoryData() {
  return axiosInstance({
    method: "get",
    url: "itemsInfo_search_service.php?move_size=all",
  });
}

export function getDataFromSession(data) {
  return axiosInstance({
    method: "POST",
    headers: { "Content-Type": "application/json" },
    url: "estimate_session_service.php",
    data: data,
  });
}

export function getDistanceGoogleAPI(from, to) {
  if (from === "" || to === "") {
    return;
  }
  const res = { status: null, distance: null, duration: null };
  var service = new window.google.maps.DistanceMatrixService();
  return new Promise((resolve, reject) => {
    service.getDistanceMatrix(
      {
        origins: [from],
        destinations: [to],
        travelMode: window.google.maps.TravelMode.DRIVING,
        unitSystem: window.google.maps.UnitSystem.METRIC,
        avoidHighways: false,
        avoidTolls: false,
      },
      function (response, status) {
        if (
          status === window.google.maps.DistanceMatrixStatus.OK &&
          response.rows[0].elements[0].status !== "ZERO_RESULTS"
        ) {
          var distance = response.rows[0].elements[0].distance.text;
          var duration = response.rows[0].elements[0].duration.text;
          res.distance = distance;
          res.duration = duration;
          res.status = 1;
          resolve(res);
        } else {
          res.status = 0;
          resolve(res);
          //unable to calculate distance.
        }
      }
    );
  });
}

export function getGeoCode(place) {
  return new Promise((resolve, reject) => {
    const value = place;
    googleMapsPlacesAPI.geo.geocode({ address: value }, function (res, status) {
      var geocode = res;
      // var parameter = { lat: place.lat(), lng: place.lng() };
      if (geocode) {
        return resolve(geocode);
      } else {
        return reject({ data: "Failed" });
      }
    });
  });
}

export function getClientTimezone() {
  let clientTZ = momentTimeZone.tz.guess();
  if (clientTZ === null || clientTZ === undefined) {
    try {
      clientTZ = Intl.DateTimeFormat().resolvedOptions().timeZone;
    } catch (err) {
      // console.log(err);
      //if system is unable to determine the client time zone set default timezone to Asia/Calcutta.
      //this is a rare case chances of occuring is ~ 0.
      clientTZ = "Asia/Calcutta";
    }
  }
  return clientTZ;
}

export function submitEstimateCallBackRequest(params) {
  return axiosInstance({
    method: "post",
    headers: { "content-type": "application/json" },
    url: "estimate_create_service.php",
    data: JSON.stringify(params),
  });
}

export function submitEstimate(params) {
  return axiosInstance({
    method: "post",
    headers: { "content-type": "application/json" },
    url: "estimate_create_service.php",
    data: JSON.stringify(params),
  });
}
export function submitCustomerEnquiryAPI(data) {
  return axiosInstance({
    method: "post",
    url: "lead_create_service.php",
    data:data
  });
}