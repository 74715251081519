import * as propertyInfo from "./propertyInfoFn";
import navigationsLinks from "../../../constants/navigation_links";
import { updateStepToActive } from "../../reducers/progress/progressReducer";
import { updateProgressSession } from "../../reducers/progress/progressFn";

//actions
export const UPDATE_PROPERTY_INFO = "propertyInfo/update";
export const CLEAR_MOVE_INFO = "propertyInfo/clear";
export const LOAD_PROPERTY_INFO = "propertyInfo/load";
export const LOAD_FROM_SESSION = "propertyInfo/loadFromLocalSession";
export const UPDATE_SUBMIT_STATUS = "propertyInfo/updateFormSubmitStatus";
export const CLEAR_FROM_ADDRESS_INFO = "propertyInfo/clear_from_address";
export const CLEAR_TO_ADDRESS_INFO = "propertyInfo/clear_to_address";
//reducers
export default function propertyInfoReducer(
  state = propertyInfo.getInitState(),
  action = {}
) {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_PROPERTY_INFO:
      return propertyInfo.updatePropertyInfo(state, payload);
    case UPDATE_SUBMIT_STATUS:
      return propertyInfo.updateFormSubmit(state, payload);
    case LOAD_FROM_SESSION:
      return propertyInfo.updateFromLocalSession(state, payload);
    case CLEAR_MOVE_INFO:
      return propertyInfo.getInitState();
    case CLEAR_FROM_ADDRESS_INFO:
      return propertyInfo.clearFromPropertyInfo(state,payload);
    case CLEAR_TO_ADDRESS_INFO:
      return propertyInfo.clearToPropertyInfo(state,payload);
    default:
      return state;
  }
}

//actions creators
export function loadPropertyInfoState(data) {
  return { type: LOAD_PROPERTY_INFO, payload: data };
}
export function updatePropertyInfoState(data) {
  return { type: UPDATE_PROPERTY_INFO, payload: data };
}
export function clearPropertyInfoState(data) {
  return { type: CLEAR_MOVE_INFO, payload: data };
}
export function updateFormSubmitStatus(data) {
  return { type: UPDATE_SUBMIT_STATUS, payload: data };
}
export function updateStateFromLocalSession(data) {
  return { type: LOAD_FROM_SESSION, payload: data };
}
export function resetFromAddressState(data) {
  return { type: CLEAR_FROM_ADDRESS_INFO, payload: data };
}
export function resetToAddressState(data) {
  return { type: CLEAR_TO_ADDRESS_INFO, payload: data };
}
//action creators which returns functions
export function thunkSubmitPropertyInfoForm(payload) {
  return async (dispatch, getState) => {
    dispatch(updateFormSubmitStatus({ status: true }));
    const { data, history } = payload;
    try {
      const propertyInfoData = propertyInfo.prepareRequestData(data);
      // const result = await saveDataToSession({
      //   form_data: propertyInfoData,
      //   type: "property_info",
      //   request_type: "update",
      // });
      // console.log("session API result", result);
      sessionStorage.setItem("property_info", JSON.stringify(propertyInfoData));
      dispatch(updateFormSubmitStatus({ status: false }));
      dispatch(updatePropertyInfoState(data));
      dispatch(updateStepToActive({ stepToUpdate: "step2" }));
      updateProgressSession("step2");

      if (payload.gotoPage !== undefined) {
        history.push(payload.gotoPage);
      } else {
        history.push(navigationsLinks.inventory_page);
      }
    } catch (e) {
      dispatch(updateFormSubmitStatus({ status: false }));

      console.error("Error", e);
    }
    // dispatch(updateMoveInfoState(data));
  };
}
export function thunkLoadPropertyInfoFromSession(payload) {
  return (dispath, state) => {
    const propertyInfoData = JSON.parse(payload.propertyInfo);
    dispath(updateStateFromLocalSession(propertyInfoData));
  };
}
