const googleMapsPlacesAPI = {
  delay: 800, // -- in milliseconds.
  placeAutoComplete: new window.google.maps.places.AutocompleteService(),
  geo: new window.google.maps.Geocoder(), // geoCoding and geoDecoding places.
};
// getCityBounds for within cityMove;

//getThePlacesAutoCompleteList

//co - ordinate matrix bounds check.
/*
        //used for withincity bounds validation.
        Reflink:https://developers.google.com/maps/documentation/javascript/reference/coordinates.	
         _____ 								   _____	
        |											|	---->	Creating a rectangle bounds of a city with 4 co-ordinate points
        |	NORTH - latitude  ,	 East - longitude  |	---->	within which latLng co-ordinate points can be found
        |											|	---->  	returns true if found else false. f:contains({lat:0,lng:0}); 			
        |	SOUTH - latitude  ,	 West - longitude  |	---->	this is achived using latlngBounds class in maps API
        |_____								 	____|	---->	   	 
*/
function checkWithinCityBounds(cityAreaBounds, placeGeoCode) {
  const south = parseFloat(cityAreaBounds["south"]);
  const west = parseFloat(cityAreaBounds["west"]);
  const north = parseFloat(cityAreaBounds["north"]);
  const east = parseFloat(cityAreaBounds["east"]);

  const sw = new window.google.maps.LatLng({ lat: south, lng: west });
  const ne = new window.google.maps.LatLng({ lat: north, lng: east });

  const cityBounds = new window.google.maps.LatLngBounds(sw, ne);

  const northEastGeo = cityBounds.getNorthEast();
  const southWestGeo = cityBounds.getSouthWest();
  const selectedAreaBounds = new window.google.maps.LatLngBounds(
    southWestGeo,
    northEastGeo
  );
  const place = placeGeoCode[0].geometry.location;
  const parameter = { lat: place.lat(), lng: place.lng() };
  return selectedAreaBounds.contains(parameter);
}

export { googleMapsPlacesAPI, checkWithinCityBounds };

// function checkWithinCityBounds(cityBounds, location, field) {
//     var northEastGeo = cityBounds.getNorthEast();
//     var southWestGeo = cityBounds.getSouthWest();

//     var selectedAreaBounds = new google.maps.LatLngBounds(
//         southWestGeo,
//         northEastGeo
//     );
//     geo.geocode({ address: location }, function (res, status) {
//         var place = res[0].geometry.location;
//         setPlaceGeoCode(field, place, "within");
//         var parameter = { lat: place.lat(), lng: place.lng() };
//         var test = selectedAreaBounds.contains(parameter);
//         // console.log("region test case",test);
//         if (field == "from") {
//             fromAddressIsWithinCityBounds = test;
//         }
//         if (field == "to") {
//             toAddressIsWithinCityBounds = test;
//         }
//         // console.log("status variable",fromAddressIsWithinCityBounds);
//         return;
//     });
// }
