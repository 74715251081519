import React from "react";
import logo from "../../../assets/images/home/logo.png"
export default function AppLoading() {
  const JSX = (
    <div className="app-loading-container">
      <div className="app-loading-logo-container">
        <img
          src={logo}
          alt="boxigo logo"
          className="img-fluid app-loading-logo"
        />
      </div>
      <div className="app-loading-text">Loading...</div>
    </div>
  );

  return JSX;
}
