export function compareGeoCodes(fromGeoCode = null, toGeoCode = null) {
  if (fromGeoCode === null && toGeoCode === null) {
    console.log("invalid parameters");
    return null;
  }

  const from = fromGeoCode[0].geometry.location;
  const to = toGeoCode[0].geometry.location;
  if (from !== null && to !== null) {
    if (from.lat() === to.lat() && from.lng() === to.lng()) {
      return false; // checking if both lat & lng of two address is same hence,same location detected.
    }
    return true;
  }
}

//validation
// Only numbers and 6 digit pincode.
export const isValidPincode = (value) => {
  if (/^[1-9][0-9]{5}$/.test(value) && value.length === 6) {
    //pincode with 6 digit and only number
    return true;
  } else {
    return false;
  }
};

export const onlyNumber = (value) => /^[0-9]*$/.test(value);
export const maxFloorNumber = (value) => parseInt(value) <= 300;
//valid name format
export const validNameFormat = (value) => /^[a-z" ".]*$/i.test(value);
//valid phone number
export const validPhoneNumberLength = (value) =>
  value.trim().length === 10 ? true : false;
//valid email id
export const validEmailId = (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
