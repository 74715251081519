import config from "../config/config";

const navigationsLinks = {
  move_details_page: "/packers-and-movers-relocation-services/get-quote",
  inventory_page:
    "/packers-and-movers-relocation-services/get-quote/items-info",
  property_info_page:
    "/packers-and-movers-relocation-services/get-quote/property-info",
  summary_page: "/packers-and-movers-relocation-services/get-quote/summary",
  login_page: "/login",
  dashboard_page: "/dashboard",
  customer_profile_page: "/my-profile",
  payment_checkout_page: "/payment-checkout",
  payment_success_page:"/payment-successful",
  skip_confirmation_page:
    "/packers-and-movers-relocation-services/get-quote/skip-confirmation",
  confirmation_page:
    "/packers-and-movers-relocation-services/get-quote/confirmation",
  edit_inventory_page: "/dashboard/edit-inventory/",
};

const BOXIGO_WEBSITE_BASE_URL = config.customerPortal;

export const BOXIGO_WEBSITE_LINKS = {
  home_page: BOXIGO_WEBSITE_BASE_URL + "packers-and-movers-relocation-services",
  about_us: BOXIGO_WEBSITE_BASE_URL + "about-us",
  vendors:
    BOXIGO_WEBSITE_BASE_URL + "packers-and-movers-relocation-services/vendor",
  contact: BOXIGO_WEBSITE_BASE_URL + "contact-us",
  customer_faq:
    BOXIGO_WEBSITE_BASE_URL +
    "packers-and-movers-relocation-services/customer-faq",
  vendor_faq:
    BOXIGO_WEBSITE_BASE_URL +
    "packers-and-movers-relocation-services/vendor-faq",
  contact_us: BOXIGO_WEBSITE_BASE_URL + "contact-us",
  vendor_login: config.vendorPortal,
  storage_space: BOXIGO_WEBSITE_BASE_URL + "storage-services",
  customer_privacy_policy_page:
    BOXIGO_WEBSITE_BASE_URL + "customer-privacy-policies",
  customer_terms_and_conditions_page:
    BOXIGO_WEBSITE_BASE_URL +
    "packers-and-movers-relocation-services/customer-terms-and-conditions",
  blog_page: BOXIGO_WEBSITE_BASE_URL + "blog",
  boxigo_site_map: BOXIGO_WEBSITE_BASE_URL + "sitemap",
  vendor_terms_and_condition:
    BOXIGO_WEBSITE_BASE_URL + "vendor-terms-and-condition",
  facebook_link: "https://www.facebook.com/boxigo.in",
  twitter_link: "https://twitter.com/Boxigo_Offical",
  linkedin_link: "https://www.linkedin.com/company/boxigo",
  instagram_link: "https://www.instagram.com/boxigo.official/",
  refund_policy:
    BOXIGO_WEBSITE_BASE_URL +
    "packers-and-movers-relocation-services/customer-faq#payment",
};

export default navigationsLinks;
