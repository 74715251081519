export function getInitState() {
  const state = {
    oldHouseAddress: "",
    oldHouseLocation: "",
    oldHouseCity: "",
    oldHouseState: "",
    oldHousePincode: "",
    oldFloorNumber: 0,
    oldHouseHasElevator: "No",
    oldHouseDistance: 0,
    oldHouseDistanceUnit: "meters",

    newHouseAddress: "",
    newHouseLocation: "",
    newHouseCity: "",
    newHouseState: "",
    newHousePincode: "",
    newFloorNumber: 0,
    newHouseHasElevator: "No",
    newHouseDistance: 0,
    newHouseDistanceUnit: "meters",

    isFormSubmitting: false,
    hasError: false,
    errorMessage: "",
    hasLoadedFromSession: false,
  };
  return state;
}
export function updatePropertyInfo(state, payload) {
  console.log("form payload in redux", payload);
  const updatedState = {
    ...state,
    oldHouseAddress: payload["old_house_address"],
    oldHouseLocation: payload["old_house_location"],
    oldHouseCity: payload["old_house_city"],
    oldHouseState: payload["old_house_state"],
    oldHousePincode: payload["old_house_pincode"],
    oldFloorNumber: payload["old_house_floor_number"],
    oldHouseHasElevator: payload["old_house_elevator_available"],
    oldHouseDistance: payload["old_house_distance"],
    oldHouseDistanceUnit: payload["old_house_distance_unit"],

    newHouseAddress: payload["new_floor_house_address"],
    newHouseLocation: payload["new_house_location"],
    newHouseCity: payload["new_house_city"],
    newHouseState: payload["new_house_state"],
    newHousePincode: payload["new_house_pincode"],
    newFloorNumber: payload["new_house_floor_number"],
    newHouseHasElevator: payload["new_house_elevator_available"],
    newHouseDistance: payload["new_house_distance"],
    newHouseDistanceUnit: payload["new_house_distance_unit"],

    hasLoadedFromSession: true,
  };
  return updatedState;
}
export function clearFromPropertyInfo(state, payload) {
  const updatedState = {
    ...state,
    oldHouseAddress: "",
    oldHouseLocation: "",
    oldHouseCity: "",
    oldHouseState: "",
    oldHousePincode: "",
  };
  return updatedState;
}
export function clearToPropertyInfo(state, payload) {
  const updatedState = {
    ...state,
    newHouseAddress: "",
    newHouseLocation: "",
    newHouseCity: "",
    newHouseState: "",
    newHousePincode: "",
  };
  return updatedState;
}
export function updateFormSubmit(state, payload) {
  const updatedState = { ...state, isMoveInfoFormSubmitting: payload.status };
  return updatedState;
}
export function updateFromLocalSession(state, payload) {
  // console.log("form payload in redux", payload);
  const updatedState = {
    ...state,
    oldHouseAddress: payload["old_house_address"],
    oldHouseLocation: payload["old_house_location"],
    oldHouseCity: payload["old_house_city"],
    oldHousePincode: payload["old_house_pincode"],
    oldFloorNumber: payload["old_house_floor_number"],
    oldHouseHasElevator: payload["old_house_elevator_available"],
    oldHouseDistance: parseFloat(payload["old_house_distance"]).toFixed(2),
    oldHouseDistanceUnit: payload["old_house_distance_unit"],
    oldHouseState: payload["old_house_state"],

    newHouseAddress: payload["new_floor_house_address"],
    newHouseLocation: payload["new_house_location"],
    newHouseCity: payload["new_house_city"],
    newHousePincode: payload["new_house_pincode"],
    newFloorNumber: payload["new_house_floor_number"],
    newHouseHasElevator: payload["new_house_elevator_available"],
    newHouseDistance: parseFloat(payload["new_house_distance"]).toFixed(2),
    newHouseDistanceUnit: payload["new_house_distance_unit"],
    newHouseState: payload["new_house_state"],
    hasLoadedFromSession: true,
  };
  return updatedState;
}

export function prepareRequestData(data) {
  const formData = {};
  formData["old_house_address"] = data["old_house_address"];
  formData["old_house_location"] = data["old_house_location"];
  formData["old_house_city"] = data["old_house_city"];
  formData["old_house_pincode"] = data["old_house_pincode"];
  formData["old_house_floor_number"] = data["old_house_floor_number"];
  formData["old_house_elevator_available"] =
    data["old_house_elevator_available"];
  formData["old_house_distance"] = data["old_house_distance"];
  formData["old_house_distance_unit"] = data["old_house_distance_unit"];
  formData["old_house_state"] = data["old_house_state"];

  formData["new_floor_house_address"] = data["new_floor_house_address"];
  formData["new_house_location"] = data["new_house_location"];
  formData["new_house_city"] = data["new_house_city"];
  formData["new_house_pincode"] = data["new_house_pincode"];
  formData["new_house_floor_number"] = data["new_house_floor_number"];
  formData["new_house_elevator_available"] =
    data["new_house_elevator_available"];
  formData["new_house_distance"] = data["new_house_distance"];
  formData["new_house_distance_unit"] = data["new_house_distance_unit"];
  formData["new_house_state"] = data["new_house_state"];
  return formData;
}
