import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import { store } from "./redux/configure_store/store";
import "antd/dist/antd.css";
import "bootstrap/dist/css/bootstrap.css";
import "react-datepicker/dist/react-datepicker.css";
import "./assets/css/datepicker.css";
import "./assets/css/app.css";
import "./assets/css/dashboard.css";
import AppLoading from "./components/skeleton/loading/AppLoading";

const App = React.lazy(() => import("./App"));

const root = (
  <Suspense fallback={<AppLoading />}>
    <Provider store={store}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </Provider>
  </Suspense>
);

ReactDOM.render(root, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
