import * as moveInfo from "./moveInfoFn";
import navigationsLinks from "../../../constants/navigation_links";
import {
  getDistanceGoogleAPI,
  getGeoCode,
} from "../../../adapters/estimate/estimateAdapters";
import { updateStepToActive } from "../../reducers/progress/progressReducer";
import { updateProgressSession } from "../../reducers/progress/progressFn";

import { compareGeoCodes } from "../../../components/common/validationFn";
import {
  resetFromAddressState,
  resetToAddressState,
} from "../property_info/propertyInfoReducer";
// import { checkWithinCityBounds } from "../../../components/common/GoogleAutoComplete";
//actions
export const UPDATE_MOVE_INFO = "moveInfo/update";
export const CLEAR_MOVE_INFO = "moveInfo/clear";
export const LOAD_MOVE_INFO = "moveInfo/load";
export const UPDATE_SUBMIT_STATUS = "moveInfo/updateFormSubmitStatus";
export const LOAD_FROM_SESSION = "moveInfo/loadFromLocalSession";
export const LOAD_FROM_URL = "moveInfo/loadFromURL";
//reducers
export default function moveInfoReducer(
  state = moveInfo.getInitState(),
  action = {}
) {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_MOVE_INFO:
      return moveInfo.updateMoveInfo(state, payload);
    case CLEAR_MOVE_INFO:
      return moveInfo.getInitState();
    case LOAD_MOVE_INFO:
      return state;
    case UPDATE_SUBMIT_STATUS:
      return moveInfo.updateMoveInfoFormSubmit(state, payload);
    case LOAD_FROM_SESSION:
      return moveInfo.updateFromLocalSession(state, payload);
    case LOAD_FROM_URL:
      return moveInfo.loadStateFromURL(state, payload);
    default:
      return state;
  }
}

//actions creators
export function loadMoveInfoState(data) {
  return { type: LOAD_MOVE_INFO, payload: data };
}
export function updateMoveInfoState(data) {
  return { type: UPDATE_MOVE_INFO, payload: data };
}
export function clearMoveInfoState(data) {
  return { type: CLEAR_MOVE_INFO, payload: data };
}
export function updateFormSubmitStatus(data) {
  return { type: UPDATE_SUBMIT_STATUS, payload: data };
}
export function updateStateFromLocalSession(data) {
  return { type: LOAD_FROM_SESSION, payload: data };
}
export function loadMoveInfoFromURL(payload) {
  return { type: LOAD_FROM_URL, payload: payload };
}

//action creators which returns functions
export function thunkSubmitMoveInfoForm(payload) {
  return async (dispatch, getState) => {
    dispatch(updateFormSubmitStatus({ status: true }));
    const { data, history } = payload;
    try {
      let addressChanged = false;
      const fromAddress =
        data["city_type"] === "Within City"
          ? data["from_area_within"]
          : data["from_area_between"];
      const toAddress =
        data["city_type"] === "Within City"
          ? data["to_area_within"]
          : data["to_area_between"];

      const distanceObj = await getDistanceGoogleAPI(fromAddress, toAddress);

      if (distanceObj.status === 1) {
        const placeCompare = (address, storeAddress) => {
          if (storeAddress === "") {
            return true;
          }
          return address.trim() === storeAddress ? true : false;
        };
        const clearPropertyHouseAddressSession = (type) => {
          if (sessionStorage.getItem("property_info") !== null) {
            const propertySession = JSON.parse(
              sessionStorage.getItem("property_info")
            );
            if (type === "from") {
              propertySession["old_house_address"] = "";
              propertySession["old_house_city"] = "";
              propertySession["old_house_location"] = "";
              propertySession["old_house_pincode"] = "";
              propertySession["old_house_state"] = "";
            } else if (type === "to") {
              propertySession["new_floor_house_address"] = "";
              propertySession["new_house_city"] = "";
              propertySession["new_house_location"] = "";
              propertySession["new_house_pincode"] = "";
              propertySession["new_house_state"] = " ";
            }
            sessionStorage.setItem("property_info", JSON.stringify(propertySession));
          }
          return;
        };

        const storeState = getState();
        const currentMoveInfoState = storeState.moveInfo;
        if (data["city_type"] === "Within City") {
          if (
            placeCompare(
              fromAddress.trim(),
              currentMoveInfoState.withinCityMovingFrom
            ) === false
          ) {
            dispatch(resetFromAddressState());
            clearPropertyHouseAddressSession("from");
            addressChanged = true;
          }
          if (
            placeCompare(
              toAddress.trim(),
              currentMoveInfoState.withinCityMovingTo
            ) === false
          ) {
            dispatch(resetToAddressState());
            clearPropertyHouseAddressSession("to");
            addressChanged = true;
          }
        }  else {
          if (
            placeCompare(
              fromAddress.trim(),
              currentMoveInfoState.interCityMovingFrom
            ) === false
          ) {
            dispatch(resetFromAddressState());
            clearPropertyHouseAddressSession("from");
            addressChanged = true;
          }
          if (
            placeCompare(
              toAddress.trim(),
              currentMoveInfoState.interCityMovingTo
            ) === false
          ) {
            dispatch(resetToAddressState());
            clearPropertyHouseAddressSession("to");
            addressChanged = true;
          }
        }

        data["distance"] = distanceObj.distance;
        data["duration"] = distanceObj.duration;
        const moveInfoData = moveInfo.prepareRequestData(data);
        sessionStorage.setItem("move_info", JSON.stringify(moveInfoData));
        updateProgressSession("step1");
        dispatch(updateFormSubmitStatus({ status: false }));
        dispatch(updateMoveInfoState(data));
        dispatch(updateStepToActive({ stepToUpdate: "step1" }));

        if (payload.gotoPage !== undefined && addressChanged === false) {
          history.push(payload.gotoPage);
        } else {
          history.push(navigationsLinks.property_info_page);
        }
      } else {
        // unable to calculate distance. {status: 0, distance: null, duration: null}
        // throw error pop up for distance.
      }
    } catch (e) {
      dispatch(updateFormSubmitStatus({ status: false }));

      console.error("Error", e);
    }
    // dispatch(updateMoveInfoState(data));
  };
}
export function thunkLoadMoveInfoFromSession(payload) {
  return (dispath, state) => {
    const moveInfoData = JSON.parse(payload.moveInfo);
    dispath(updateStateFromLocalSession(moveInfoData));
  };
}

export function thunkUpdateMoveInfoFromURL(payload) {
  return (dispatch, state) => {
    dispatch(updateFormSubmitStatus({ status: true }));

    const {
      propertySize,
      movingFrom,
      movingTo,
      movingOn,
      moveType,
      selectCity,
      isFlexible,
    } = payload;
    const getGeographicalData = async () => {
      const distanceObj = await getDistanceGoogleAPI(movingFrom, movingTo);
      const fromGeoCode = await getGeoCode(movingFrom);
      const toGeoCode = await getGeoCode(movingTo);

      const checkResult = compareGeoCodes(fromGeoCode, toGeoCode);
      return {
        distanceMatrix: distanceObj,
        geoCheck: checkResult,
      };
    };
    getGeographicalData()
      .then((geoData) => {
        const payload = {
          moveType: moveType,
          selectCity: selectCity,
          propertySize: propertySize,
          movingFrom: movingFrom,
          movingTo: movingTo,
          movingOn: movingOn,
          distance: geoData.distanceMatrix["distance"],
          duration: geoData.distanceMatrix["duration"],
          isMoveFlexible: isFlexible === "0" ? false : true,
        };
        updateProgressSession("step1");
        const moveInfoData = {
          city_type: moveType,
          distance: payload.distance,
          duration: payload.duration,
          move_date_flexible: true,
          moving_date: payload.movingOn,
          moving_from: payload.movingFrom,
          moving_to: payload.movingTo,
          property_size: payload.propertySize,
          select_city: payload.selectCity,
        };
        sessionStorage.setItem("move_info", JSON.stringify(moveInfoData));
        dispatch(loadMoveInfoFromURL(payload));
        // dispatch(updateFormSubmitStatus({ status: false }));
        // dispatch(updateMoveInfoState(data));
        dispatch(updateStepToActive({ stepToUpdate: "step1" }));
        dispatch(updateFormSubmitStatus({ status: false }));
      })
      .catch((err) => console.error(err));
  };
}
