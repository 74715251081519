export function getInitState() {
  const state = {
    moveType: "Within City",
    selectCity: "",
    withinCityPropertySize: "1 BHK",
    withinCityMovingFrom: "",
    withinCityMovingTo: "",
    withinCityMovingOnDate: "",
    withinCityDistance: "",
    withinCityhasFlexibleDate: false,

    interCityPropertySize: "1 BHK",
    interCityMovingFrom: "",
    interCityMovingTo: "",
    interCityMovingOnDate: "",
    interCityDistance: "",
    interCityhasFlexibleDate: false,

    isMoveInfoFormSubmitting: false,
    hasError: false,
    errorMessage: "",
    hasLoadedFromSession: false,
  };
  return state;
}

export function updateMoveInfo(state, payload) {
  //   console.log("state in reducers is",state);
  // clear the intercity or within city based movetype on submit
  if (payload.city_type === "Within City") {
    const updatedState = {
      ...state,
      moveType: payload["city_type"],
      selectCity: payload["select_city"],
      withinCityPropertySize: payload["property_size_within_city"],
      withinCityMovingFrom: payload["from_area_within"],
      withinCityMovingTo: payload["to_area_within"],
      withinCityMovingOnDate: payload["move_date_within_city"],
      withinCityDistance: payload["distance"],
      withinCityDuration: payload["duration"],
      withinCityhasFlexibleDate: payload["move_date_flexible_within"],

      interCityPropertySize: "1 BHK",
      interCityMovingFrom: "",
      interCityMovingTo: "",
      interCityMovingOnDate: "",
      interCityDistance: "",
      interCityhasFlexibleDate: false,
      hasLoadedFromSession: true
    };
    return updatedState;
  }
  if (payload.city_type === "Inter City") {
    const updatedState = {
      ...state,
      moveType: payload["city_type"],
      interCityPropertySize: payload["property_size_between_city"],
      interCityMovingFrom: payload["from_area_between"],
      interCityMovingTo: payload["to_area_between"],
      interCityMovingOnDate: payload["move_date_between_city"],
      interCityDistance: payload["distance"],
      interCityDuration: payload["duration"],
      interCityhasFlexibleDate: payload["move_date_flexible_between"],

      selectCity: "",
      withinCityPropertySize: "1 BHK",
      withinCityMovingFrom: "",
      withinCityMovingTo: "",
      withinCityMovingOnDate: "",
      withinCityDistance: "",
      withinCityhasFlexibleDate: false,
      hasLoadedFromSession: true
    };

    return updatedState;
  }
  return state;
}

export function updateMoveInfoFormSubmit(state, payload) {
  const updatedState = { ...state, isMoveInfoFormSubmitting: payload.status };
  return updatedState;
}

export function updateFromLocalSession(state, payload) {
  if (payload.city_type === "Within City") {
    const updatedState = {
      ...state,
      hasLoadedFromSession: true,

      moveType: payload["city_type"],
      selectCity: payload["select_city"],
      withinCityPropertySize: payload["property_size"],
      withinCityMovingFrom: payload["moving_from"],
      withinCityMovingTo: payload["moving_to"],
      withinCityMovingOnDate: new Date(payload["moving_date"]),
      withinCityDistance: payload["distance"],
      withinCityDuration: payload["duration"],
      withinCityhasFlexibleDate: payload["move_date_flexible"],

      interCityPropertySize: "1 BHK",
      interCityMovingFrom: "",
      interCityMovingTo: "",
      interCityMovingOnDate: "",
      interCityDistance: "",
      interCityDuration:"",
      interCityhasFlexibleDate: false,
    };
    return updatedState;
  }
  if (payload.city_type === "Inter City") {
    const updatedState = {
      ...state,
      hasLoadedFromSession: true,

      moveType: payload["city_type"],
      interCityPropertySize: payload["property_size"],
      interCityMovingFrom: payload["moving_from"],
      interCityMovingTo: payload["moving_to"],
      interCityMovingOnDate: new Date(payload["moving_date"]),
      interCityDistance: payload["distance"],
      interCityDuration: payload["duration"],
      interCityhasFlexibleDate: payload["move_date_flexible"],

      selectCity: "",
      withinCityPropertySize: "1 BHK",
      withinCityMovingFrom: "",
      withinCityMovingTo: "",
      withinCityMovingOnDate: "",
      withinCityDistance: "",
      withinCityDuration:"",
      withinCityhasFlexibleDate: false,
    };

    return updatedState;
  }
  return state;
}

export function prepareRequestData(data) {
  const formData = {};
  if (data.city_type === "Within City") {
    formData["city_type"] = data["city_type"];
    formData["select_city"] = data["select_city"];
    formData["property_size"] = data["property_size_within_city"];
    formData["moving_from"] = data["from_area_within"];
    formData["moving_to"] = data["to_area_within"];
    formData["moving_date"] = data["move_date_within_city"];
    formData["move_date_flexible"] = data["move_date_flexible_within"];
    formData["distance"] = data["distance"];
    formData["duration"] = data["duration"];
  } else {
    formData["city_type"] = data["city_type"];
    formData["property_size"] = data["property_size_between_city"];
    formData["select_city"] = "";
    formData["moving_from"] = data["from_area_between"];
    formData["moving_to"] = data["to_area_between"];
    formData["moving_date"] = data["move_date_between_city"];
    formData["move_date_flexible"] = data["move_date_flexible_between"];
    formData["distance"] = data["distance"];
    formData["duration"] = data["duration"];
  }
  // [distance] => 0.8 km
  // [duration] => 3 mins
  // [city_type] => within city
  // [select_city] => Bengaluru
  // [property_size] => 1 BHK
  // [moving_from] => Koramangala, Bengaluru, Karnataka
  // [moving_to] => Ejipura, Bengaluru, Karnataka
  // [moving_date] => 03/31/2021 11:44 AM
  // [move_date_flexible] => on

  return formData;
}
export function loadStateFromURL(state, payload) {
// distance: "0.8 km"
// duration: "3 mins"
// isMoveFlexible: "1"
// moveType: "Within City"
// movingFrom: "koramangal, karnataka, bangalore"
// movingOn: "2020-11-20 10:10:10"
// movingTo: "ejipura"
// propertySize: "1 BHK"
// selectCity: "bangalore"
  if (payload.moveType === "Within City") {
    const updatedState = {
      ...state,
      hasLoadedFromSession: true,
      moveType: payload["moveType"],
      selectCity: payload["selectCity"],
      withinCityPropertySize: payload["propertySize"],
      withinCityMovingFrom: payload["movingFrom"],
      withinCityMovingTo: payload["movingTo"],
      withinCityMovingOnDate: new Date(payload["movingOn"]),
      withinCityDistance: payload["distance"],
      withinCityDuration: payload["duration"],
      withinCityhasFlexibleDate: payload["isMoveFlexible"],

      interCityPropertySize: "1 BHK",
      interCityMovingFrom: "",
      interCityMovingTo: "",
      interCityMovingOnDate: "",
      interCityDistance: "",
      interCityhasFlexibleDate: false,
    };
    return updatedState;
  }
  if (payload.moveType === "Inter City") {
    const updatedState = {
      ...state,
      hasLoadedFromSession: true,

      moveType: payload["moveType"],
      interCityPropertySize: payload["propertySize"],
      interCityMovingFrom: payload["movingFrom"],
      interCityMovingTo: payload["movingTo"],
      interCityMovingOnDate: new Date(payload["movingOn"]),
      interCityDistance: payload["distance"],
      interCityDuration: payload["duration"],
      interCityhasFlexibleDate: payload["isMoveFlexible"],

      selectCity: "",
      withinCityPropertySize: "1 BHK",
      withinCityMovingFrom: "",
      withinCityMovingTo: "",
      withinCityMovingOnDate: "",
      withinCityDistance: "",
      withinCityhasFlexibleDate: false,
    };

    return updatedState;
  }
  return state;
}