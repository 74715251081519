import Immutable from "immutable";
export function loadFromAPI(state, action) {
  const updatedState = { ...state };
  return updatedState;
}
export function loadSession(state, action) {
  const updatedState = state
    .set("inventory", Immutable.fromJS(action.payload.inventory))
    .set("customItems", Immutable.fromJS(action.payload.customItems));
  return updatedState;
}

export function loadEditInventoryData(state, action) {
  const updatedState = state
    .set("inventory", Immutable.fromJS(action.payload.inventory))
    .set("customItems", Immutable.fromJS(action.payload.customItems));
  return updatedState;
}

export function updateItemQty(state, action) {
  // console.log("payload is", action.payload);
  let itemQty = state.getIn(action.payload.path);

  if (action.payload.operation === "increment") {
    itemQty = itemQty + 1;
    return state.setIn(action.payload.path, itemQty);
  } else if (action.payload.operation === "decrement" && itemQty > 0) {
    itemQty = itemQty - 1;
    return state.setIn(action.payload.path, itemQty);
  }
  return state;
}

export function updateItemSize(state, action) {
  //loop through size and reset all
  // ["inventory",0,"category",0,"items",0,"size"]
  const size = state.getIn(action.payload.path);
  const updateSize = size.map((data, index) => {
    if (index === action.payload.indexToUpdate) {
      return data.set("selected", true);
    }
    return data.set("selected", false);
  });
  const pathToUpdate = action.payload.path;
  return state.setIn(pathToUpdate, updateSize);
}

export function updateSingleType(state, action) {
  //loop through size and reset all
  // ["inventory",0,"category",0,"items",0,"size"]
  const typeList = state.getIn(action.payload.path);
  const updatedTypeList = typeList.map((data, index) => {
    if (data.get("id") === action.payload.selectedValue) {
      return data.set("selected", true);
    }
    return data.set("selected", false);
  });
  const pathToUpdate = action.payload.path;
  return state.setIn(pathToUpdate, updatedTypeList);
}

export function updateMultiType(state, action) {
  const typeList = state.getIn(action.payload.path);
  const updatedTypeList = typeList.map((data, index) => {
    if (data.get("id") === action.payload.selectedId) {
      if (action.payload.checkStatus === true) {
        return data.set("selected", true);
      }
      return data.set("selected", false);
    }
    return data;
  });
  const pathToUpdate = action.payload.path;
  return state.setIn(pathToUpdate, updatedTypeList);
}

export function deleteItem(state, action) {
  const { itemId, pathKeyId } = action.payload;
  const inventoryData = state.get("inventory");
  // let mainIndexKey = "";
  // let subIndexKey = "";
  const updatedInventoryState = inventoryData.map((mainCategory, mainIndex) => {
    if (mainCategory.get("id") === pathKeyId.mainCategoryId) {
      const updatedCategory = mainCategory
        .get("category")
        .map((subCategory, subIndex) => {
          if (subCategory.get("id") === pathKeyId.subCategoryId) {
            const updatedSubCategory = subCategory
              .get("items")
              .map((item, itemIndex) => {
                if (item.get("id") === itemId) {
                  return item
                    .set("qty", 0)
                    .set("childItems", Immutable.fromJS([]));
                }
                return item;
              });
            return subCategory.set("items", updatedSubCategory);
          }
          return subCategory;
        });
      return mainCategory.set("category", updatedCategory);
    }
    return mainCategory;
  });
  return state.set("inventory", updatedInventoryState);
}

export function resetItems(state, action) {
  const inventory = state.set(
    "inventory",
    Immutable.fromJS(action.payload.inventory)
  );
  const updatedState = inventory.setIn(
    ["customItems", "items"],
    Immutable.fromJS([])
  );
  return updatedState;
}
export function addCustomItemsData(state, action) {
  const customItemsList = state.getIn(["customItems", "items"]);
  return state.setIn(
    ["customItems", "items"],
    customItemsList.push(action.payload)
  );
}

export function updateUnitType(state, action) {
  const type = action.payload.type;
  return state.setIn(["customItems", "units"], type);
}

export function deleteInventoryCustomItems(state, action) {
  const id = action.payload.id;
  const updatedItemsList = state
    .getIn(["customItems", "items"])
    .filter((data) => {
      return data.get("id") === id ? false : true;
    });
  return state.setIn(["customItems", "items"], updatedItemsList);
}

export function updateCustomItemsList(state, action) {
  const dataToUpdate = action.payload;
  const id = action.payload.get("id");
  const updatedCustomItems = state
    .getIn(["customItems", "items"])
    .map((customItem) => {
      if (customItem.get("id") === id) {
        return dataToUpdate;
      }
      return customItem;
    });
  return state.setIn(["customItems", "items"], updatedCustomItems);
}

export function addItems(state, action) {
  const { accessPath } = action.payload;
  const makeChildItemFromParent = (parentItem) => {
    // Delete the parent list of child.
    // Reset all options to default and qty to zero.
    // Only parent can create child card item.
    let childItem = parentItem.deleteIn(["childItems"]).set("qty", 1);
    //-------------------------------------------------------
    // Reset Qty and Reset Size, Reset Type.
    if (1 === 0) {
      // disables the reset logic.
      //reset the size of child copy
      if (childItem.getIn(["meta", "hasSize"])) {
        let resetSize = childItem.get("size").map((itemSize, index) => {
          if (index === 0) {
            return itemSize.set("selected", true);
          }
          return itemSize.set("selected", false);
        });
        childItem = childItem.set("size", resetSize);
      }
      // reset the type options for child copy
      if (childItem.getIn(["meta", "hasType"])) {
        let resetTypeList = childItem.get("type").map((type) => {
          return type.set("selected", false);
        });
        childItem = childItem.set("type", resetTypeList);
      }
    }

    //-------------------------------------------------------

    childItem = childItem.update(
      "id",
      (value) => childItem.get("id") + Math.random().toString(36).substr(2, 9)
    );
    const updatedParentItem = parentItem.update("childItems", (childItemList) =>
      childItemList.push(childItem)
    );
    return updatedParentItem;
  };
  const parentItem = state.getIn(accessPath);
  const updatedState = state.updateIn(accessPath, () =>
    makeChildItemFromParent(parentItem)
  );
  return updatedState;
}

export function deleteChildItems(state, action) {
  const { itemId, childItemId, pathKeyId } = action.payload;
  const inventoryData = state.get("inventory");
  // let mainIndexKey = "";
  // let subIndexKey = "";
  const updatedInventoryState = inventoryData.map((mainCategory, mainIndex) => {
    if (mainCategory.get("id") === pathKeyId.mainCategoryId) {
      const updatedCategory = mainCategory
        .get("category")
        .map((subCategory, subIndex) => {
          if (subCategory.get("id") === pathKeyId.subCategoryId) {
            const updatedSubCategory = subCategory
              .get("items")
              .map((item, itemIndex) => {
                if (item.get("id") === itemId) {
                  return item.set(
                    "childItems",
                    item
                      .get("childItems")
                      .filter((childItem) =>
                        childItem.get("id") === childItemId ? false : true
                      )
                  );
                }
                return item;
              });
            return subCategory.set("items", updatedSubCategory);
          }
          return subCategory;
        });
      return mainCategory.set("category", updatedCategory);
    }
    return mainCategory;
  });
  return state.set("inventory", updatedInventoryState);
}

export function deleteVariationCards(state, action) {
  const { path, childItemId } = action.payload;
  const childItemsList = state.getIn(path);
  const updatedChildItemsList = childItemsList.filter((childItem) =>
    childItem.get("id") === childItemId ? false : true
  );
  return state.setIn(path, updatedChildItemsList);
}
export function resetEditItems(state, action) {
  const updatedState = state.set(
    "inventory",
    Immutable.fromJS(action.payload.inventory)
  ).set("customItems",Immutable.fromJS(action.payload.customItems));
  return updatedState;
}