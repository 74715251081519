export function getUserInfoFromSession() {
  const userSession = sessionStorage.getItem("userSessionData");
  const userData = JSON.parse(userSession);
  return userData;
}

export function updateUserInfoInSession(data) {
  if (sessionStorage.getItem("userSessionData")) {
    const userSession = sessionStorage.getItem("userSessionData");
    const userData = JSON.parse(userSession);
    userData["email"] = data["email"];
    userData["customerName"] = data["customerName"];
    userData["isEmailVerified"] = data["isEmailVerified"];
    userData["phone"] = data["phone"];
    userData["alternatePhone"] = data["alternatePhone"];
    sessionStorage.setItem("userSessionData", JSON.stringify(userData));
  }
  return;
}

export function updateCheckOutInfoSession(data) {
  const checkOutInfo = JSON.stringify(data);
  sessionStorage.setItem("checkoutInfo", checkOutInfo);
}

export function clearCheckInfoSession() {
  if (sessionStorage.getItem("checkoutInfo") !== undefined) {
    return sessionStorage.removeItem("checkoutInfo");
  }
  return;
}
