export function updateProgressStep(state, action) {
  const { payload } = action;
  const updateState = {
    ...state,
    [payload.stepToUpdate]: 1,
  };
  return updateState;
}

export function loadProgressData(state, action) {
  const { payload } = action;
  return payload.state;
}

export function updateProgressSession(stepToUpdate) {
  if (sessionStorage.getItem("progressData") !== null) {
    let progressData = JSON.parse(sessionStorage.getItem("progressData"));
    progressData[stepToUpdate] = 1;
    sessionStorage.setItem("progressData", JSON.stringify(progressData));
  } else {
    let progressData = { step1: 0, step2: 0, step3: 0, step4: 0 };
    progressData[stepToUpdate] = 1;
    sessionStorage.setItem("progressData", JSON.stringify(progressData));
  }
}
