export function loginUser(state, action) {
  const updateState = {
    ...state,
    loggedIn: action.payload.loggedIn === true ? true : false,
    email: action.payload.email,
    customerName: action.payload.customerName,
    isEmailVerified: action.payload.isEmailVerified === "1" ? true : false,
    isPhoneVerified: action.payload.isPhoneVerified === "1" ? true : false,
    phone: action.payload.phone,
    alternatePhone: action.payload.alternatePhone,
    userId: action.payload.userId,
  };
  return updateState;
}

export function logoutUser(state, action) {
  const updateState = {
    ...state,
    loggedIn: false,
    email: "",
    customerName: "",
    isEmailVerified: false,
    isPhoneVerified: false,
    userId: "",
    phone: "",
    alternatePhone: "",
  };
  return updateState;
}

export function resetToInitialState(state, action) {
  const updateState = {
    loggedIn: false,
    email: "",
    customerName: "",
    isEmailVerified: false,
    isPhoneVerified: false,
    phone: "",
    userId: "",
    alternatePhone: "",
  };
  return updateState;
}
export function updateLoginData(state, action) {
  let updatedState = { ...state };
  if (state.loggedIn === true) {
    updatedState["email"] = action.payload.email;
    updatedState["customerName"] = action.payload.customerName;
    updatedState["phone"] = action.payload.phone;
    updatedState["isEmailVerified"] =
      action.payload.isEmailVerified === "1" ? true : false;
    updatedState["alternatePhone"] = action.payload.alternatePhone
  }
  return updatedState;
}