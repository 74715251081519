/* Configuration */
const env = process.env.REACT_APP_ENVIRONMENT;
const config = {
  baseUrl: "localhost:3000",
  apiBaseUrl: "http://localhost/boxigo-backend-api/product/",
  customerPortal: "http://localhost/boxigo_customer_portal/",
  vendorPortal: "http://vendor.boxigo.in/",
  adminPortal: "http://admin.boxigo.in/",
  mainDomain: "",
  vendorImagesPath: "http://localhost/vendor/",
  leadModalDelay:18000,
  skipInventoryModalDelay:22000
};

switch (env) {
  case "dev":
    //Development Environement Config.
    config.baseUrl = "localhost:3000";
    config.apiBaseUrl = "http://localhost/boxigo-backend-api/product/";
    config.customerPortal = "http://localhost/boxigo_customer_portal/";
    config.vendorPortal = "http://test.vendor.boxigo.in/";
    config.adminPortal = "http://test.admin.boxigo.in/";
    config.mainDomain = "http://localhost/";
    config.vendorImagesPath = "http://localhost/vendor/";
    break;
  case "test":
    //Test Environement Config.
    config.baseUrl = "http://test.app.boxigo.in";
    config.apiBaseUrl = "http://test.boxigo.in/boxigo-backend-api/product/";
    config.customerPortal = "http://test.boxigo.in/";
    config.vendorPortal = "http://test.vendor.boxigo.in/";
    config.adminPortal = "http://test.admin.boxigo.in/";
    config.mainDomain = "http://test.boxigo.in/";
    config.vendorImagesPath = "http://test.boxigo.in/vendor-images/";
    break;
  case "prod":
    //Production Environement Config.
    config.baseUrl = "https://estimate.boxigo.in/";
    config.apiBaseUrl = "https://api.boxigo.in/product/";
    config.customerPortal = "https://boxigo.in/";
    config.vendorPortal = "https://vendor.boxigo.in/";
    config.adminPortal = "https://admin.boxigo.in/";
    config.mainDomain = "https://boxigo.in/";
    config.vendorImagesPath = "https://boxigo.in/vendor-images/";
    break;
  default:
    config.baseUrl = "localhost:3000";
    config.apiBaseUrl = "http://localhost/boxigo-backend-api/";
    config.customerPortal = "http://localhost/boxigo_customer_portal/";
    config.vendorPortal = "http://test.vendor.boxigo.in/";
    config.adminPortal = "http://test.admin.boxigo.in/";
    config.mainDomain = "http://localhost/";
    config.vendorImagesPath = "http://localhost/vendor-images/";
    break;
}

export default config;
