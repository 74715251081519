// ---- redux reducer follows modular duck design pattern for organizing redux structure
// https://github.com/erikras/ducks-modular-redux
// 1.Actions
// 2.Reducers
// 3.Action Creators
// 4(if any). side effects, redux thunk

// import { Map } from "immutable";
import { loginUser, logoutUser, updateLoginData } from "./userInfoFn";
import { clearMoveInfoState } from "../move_info/moveInfoReducer";
import { clearPropertyInfoState } from "../property_info/propertyInfoReducer";
import { clearInventory } from "../inventory/inventoryReducer";
import { resetProgress } from "../progress/progressReducer";
import { updateUserInfoInSession } from "../../../adapters/session/localSessionAdapter";

const initState = {
  loggedIn: false,
  email: "",
  customerName: "",
  isEmailVerified: false,
  isPhoneVerified: false,
  phone: "",
  userId: "",
};
//actions
export const LOGIN_USER = "user/login";
export const LOGOUT_USER = "user/logout";
export const LOAD_FROM_SESSION = "user/load_from_session";
export const UPDATE_USER_SESSION = "user/update_user_data";

// reducer
export default function reducer(state = initState, action = {}) {
  switch (action.type) {
    case LOGIN_USER:
      return loginUser(state, action);
    case LOGOUT_USER:
      return logoutUser(state, action);
    case LOAD_FROM_SESSION:
      return loginUser(state, action);
    case UPDATE_USER_SESSION:
      return updateLoginData(state, action);
    default:
      return state;
  }
}

// action creator

export function updateToLogin(payload) {
  return { type: LOGIN_USER, payload: payload };
}

export function updateToLogout(payload) {
  return { type: LOGOUT_USER, payload: payload };
}

export function loadFromLocalSession(payload) {
  return { type: LOAD_FROM_SESSION, payload: payload };
}
export function updateUserSessionData(payload) {
  return { type: UPDATE_USER_SESSION, payload: payload };
}
// thunk functions
export function thunkLoadLoginFromSession(payload) {
  return (dispatch, state) => {
    dispatch(loadFromLocalSession(payload));
  };
}

export function thunkLoginUser(payload) {
  return (dispatch, state) => {
    dispatch(updateToLogin(payload));
    payload.history.push("/dashboard");
  };
}

export function thunkLogoutUser(params) {
  return (dispatch, state) => {
    sessionStorage.removeItem("userSessionData");
    sessionStorage.removeItem("progressData");
    sessionStorage.removeItem("summaryInfo");
    sessionStorage.removeItem("move_info");
    sessionStorage.removeItem("property_info");
    sessionStorage.removeItem("inventory_info");
    dispatch(clearMoveInfoState());
    dispatch(clearPropertyInfoState());
    dispatch(resetProgress());
    dispatch(clearInventory());
    dispatch(updateToLogout(params));

    params.history.push("/login");
  };
}
export function thunkUpdateUserData(payload) {
  return (dispatch, state) => {
    updateUserInfoInSession(payload);
    dispatch(updateUserSessionData(payload));
  };
}
