import { updateProgressStep, loadProgressData } from "./progressFn";
import { clearMoveInfoState } from "../move_info/moveInfoReducer";
import { clearPropertyInfoState } from "../property_info/propertyInfoReducer";
import { clearInventory } from "../inventory/inventoryReducer";

const initState = {
  step1: 0,
  step2: 0,
  step3: 0,
  step4: 0,
};
export const LOAD = "load/progress";
export const UPDATE_PROGRESS = "update/progress";
export const RESET_PROGRESS = "reset/progress";

export default function reducer(state = initState, action) {
  switch (action.type) {
    case LOAD:
      return loadProgressData(state, action);
    case UPDATE_PROGRESS:
      return updateProgressStep(state, action);
    case RESET_PROGRESS:
      return initState;
    default:
      return state;
  }
}

export function loadProgress(payload) {
  return { type: LOAD, payload: payload };
}

export function updateStepToActive(payload) {
  return { type: UPDATE_PROGRESS, payload: payload };
}

export function resetProgress(payload) {
  return { type: RESET_PROGRESS, payload: payload };
}

export function thunkClearEstimateData(payload) {
  return (dispatch) => {
    sessionStorage.removeItem("progressData");
    sessionStorage.removeItem("summaryInfo");
    sessionStorage.removeItem("move_info");
    sessionStorage.removeItem("property_info");
    sessionStorage.removeItem("inventory_info");
    dispatch(clearMoveInfoState());
    dispatch(clearPropertyInfoState());
    dispatch(clearInventory());
    dispatch(resetProgress());
  };
}
