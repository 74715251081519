import Immutable from "immutable";
import {
  updateItemQty,
  updateItemSize,
  updateMultiType,
  updateSingleType,
  addCustomItemsData,
  updateUnitType,
  deleteInventoryCustomItems,
  updateCustomItemsList,
  deleteItem,
  resetItems,
  addItems,
  deleteChildItems,
  loadSession,
  deleteVariationCards,
  loadEditInventoryData,
  resetEditItems,
} from "./inventoryFn";
//Inventory Reducer
//init state
const initState = Immutable.fromJS({
  inventory: [],
  customItems: {
    units: "feet",
    items: [],
  },
});
//actions
export const LOAD = "inventory/load_inventory_json";
export const LOAD_EDIT_INVENTORY = "inventory/load_edit_inventory";
export const LOAD_FROM_SESSION = "inventory/load_from_session";
export const UPDATE_ITEM_QTY = "inventory/update_item_qty";
export const UPDATE_ITEM_SIZE = "inventory/update_item_size";
export const UPDATE_ITEM_SINGLE_TYPE = "inventory/update_item_single_type";
export const UPDATE_ITEM_MULTI_TYPE = "inventory/update_item_multi_type";
export const DELETE_IVENTORY_ITEMS = "inventory/delete_inventory_item";
export const RESET_INVENTORY = "inventory/reset_inventory";
export const ADD_VARIATION = "inventory/add_variation";
export const DELETE_VARIATION = "inventory/delete_variation";
export const CLEAR_INVENTORY = "inventory/clear_inventory";
//inventory custom items
export const ADD_CUSTOM_ITEM = "inventory/add_custom_item";
export const UPDATE_CUSTOM_ITEM_UNITS = "inventory/update_items_units";
export const DELETE_CUSTOM_ITEMS = "inventory/delete_custom_items";
export const UPDATE_CUSTOM_ITEMS = "inventory/update_custom_items";
export const DELETE_VARIATION_FROM_CARDS =
  "inventory/delete_varidation_from_card";
export const RESET_EDIT_INVENTORY = "inventory/reset_edit_inventory";
//reducer
export default function reducer(state = initState, action = {}) {
  switch (action.type) {
    case LOAD:
      const updatedState = state
        .set("inventory", Immutable.fromJS(action.payload.inventory))
        .set(
          "customItems",
          Immutable.fromJS({
            units: "feet",
            items: [],
          })
        );
      return updatedState;
    case LOAD_EDIT_INVENTORY:
      return loadEditInventoryData(state, action);
    case LOAD_FROM_SESSION:
      return loadSession(state, action);
    case UPDATE_ITEM_QTY:
      return updateItemQty(state, action);
    case UPDATE_ITEM_SIZE:
      return updateItemSize(state, action);
    case UPDATE_ITEM_SINGLE_TYPE:
      return updateSingleType(state, action);
    case UPDATE_ITEM_MULTI_TYPE:
      return updateMultiType(state, action);
    case DELETE_VARIATION_FROM_CARDS:
      return deleteVariationCards(state, action);
    case DELETE_IVENTORY_ITEMS:
      return deleteItem(state, action);
    case RESET_INVENTORY:
      return resetItems(state, action);
    case RESET_EDIT_INVENTORY:
      return resetEditItems(state, action);
    case ADD_VARIATION:
      return addItems(state, action);
    case DELETE_VARIATION:
      return deleteChildItems(state, action);
    case ADD_CUSTOM_ITEM:
      return addCustomItemsData(state, action);
    case UPDATE_CUSTOM_ITEM_UNITS:
      return updateUnitType(state, action);
    case DELETE_CUSTOM_ITEMS:
      return deleteInventoryCustomItems(state, action);
    case UPDATE_CUSTOM_ITEMS:
      return updateCustomItemsList(state, action);
    case CLEAR_INVENTORY:
      return initState;
    default:
      return state;
  }
}

//action creator
export function loadInventory(payload) {
  return { type: LOAD, payload: payload };
}
export function loadEditInventory(payload) {
  return { type: LOAD_EDIT_INVENTORY, payload: payload };
}
export function clearInventory(payload) {
  return { type: CLEAR_INVENTORY, payload: payload };
}
export function loadFromSession(payload) {
  return { type: LOAD_FROM_SESSION, payload: payload };
}
export function updateInventoryItemQty(payload) {
  return { type: UPDATE_ITEM_QTY, payload: payload };
}

export function updateInventoryItemSize(payload) {
  return { type: UPDATE_ITEM_SIZE, payload: payload };
}

export function updateInventorySingleType(payload) {
  return { type: UPDATE_ITEM_SINGLE_TYPE, payload: payload };
}
export function updateInventoryMultiType(payload) {
  return { type: UPDATE_ITEM_MULTI_TYPE, payload: payload };
}
export function deleteInventoryItem(payload) {
  return { type: DELETE_IVENTORY_ITEMS, payload: payload };
}
export function resetInventory(payload) {
  return { type: RESET_INVENTORY, payload: payload };
}
export function resetEditInventory(payload) {
  return { type: RESET_EDIT_INVENTORY, payload: payload };
}
export function addVariationItems(payload) {
  return { type: ADD_VARIATION, payload: payload };
}
export function deleteInventoryVariationItems(payload) {
  return { type: DELETE_VARIATION, payload: payload };
}
export function deleteChildItemFromCards(payload) {
  return { type: DELETE_VARIATION_FROM_CARDS, payload: payload };
}
//inventory custom items
export function addCustomItems(payload) {
  return { type: ADD_CUSTOM_ITEM, payload: payload };
}
export function updateItemsUnitType(payload) {
  return { type: UPDATE_CUSTOM_ITEM_UNITS, payload: payload };
}
export function deleteCustomItems(payload) {
  return { type: DELETE_CUSTOM_ITEMS, payload: payload };
}
export function updateCustomItems(payload) {
  return { type: UPDATE_CUSTOM_ITEMS, payload: payload };
}
//redux side effect and thunk functions
// export function getInventory(){
//     return (dispatch,state)={

//     }
// }
