import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import UserInfoReducer from "../reducers/user_info/userInfoReducer";
import moveInfoReducer from "../reducers/move_info/moveInfoReducer";
import propertyInfoReducer from "../reducers/property_info/propertyInfoReducer";
import inventoryReducer from "../reducers/inventory/inventoryReducer";
import progressReducer from "../reducers/progress/progressReducer";
const composeTools =
  process.env.REACT_APP_ENVIRONMENT === "dev"
    ? composeWithDevTools(applyMiddleware(thunk))
    : compose(applyMiddleware(thunk));

const store = createStore(
  combineReducers({
    userInfo: UserInfoReducer,
    moveInfo: moveInfoReducer,
    propertyInfo: propertyInfoReducer,
    inventoryInfo: inventoryReducer,
    progressInfo:progressReducer
  }),
  composeTools
);
// console.log(store.getState());
// store.dispatch(updateLoggedIn(false));
export { store };
